.Table {
  border-collapse: collapse;

  * td {
    padding-left: 5px;
    padding-right: 10px;

    &.num-right {
      text-align: right
    }
  }

  * th {
    padding-right: 10px;
  }

  &.sticky-first-col {
    tbody tr td:first-child {
      position: sticky;
      left: 0;
      //background-clip: padding-box;
      z-index: 1;
    }

    thead tr th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  &.sticky-header {
    thead tr th {
      position: sticky;
      top: 0;
      z-index: 1;
      padding-top: 4px;
      background-clip: padding-box;
    }
  }

  &.tree-table {
    td:first-child {
      padding-left: 0;
    }

    --marker-background: #f2f2f2;
    --collapse-marker-background: #cccccc;
    --expand-marker-background: #f2f2f2;
    --marker-color: black;
    --marker-pointer-events: all;

    &.hide-toggle {
      --marker-background: transparent;
      --collapse-marker-background: transparent;
      --expand-marker-background: transparent;
      --marker-color: transparent;
      --marker-pointer-events: none;
    }

    .toggle-marker {
      display: inline;
      padding: 0;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      overflow: visible;
      background: var(--marker-background);
      color: var(--marker-color);
      font-size: 1em;
      float: left;
      align-items: center;
      text-align: center;
      cursor: pointer;
      pointer-events: none;
      border: thin solid transparent;

      &.expand-tree {
        background: var(--expand-marker-background);
        color: var(--marker-color);
      }

      &.collapse-tree {
        background: var(--collapse-marker-background);
        color: var(--marker-color);
        border: thin white solid;
      }

      &.no-action {
        background: transparent;
        color: transparent
      }
    }
  }

  * tr {
    &.error {
      td {
        &:first-child {
          border-left-color: var(--finbi-cell-error-color)
        }
      }
    }
  }

  &.row-nums {
    counter-reset: rowcounter;

    tbody {
      tr {
        counter-increment: rowcounter;

        &:before {
          content: counter(rowcounter);
          padding-right: 5px;
          color: rgba(40, 43, 43, 0.7);
        }
      }
    }
  }

  &.with-marquee {
    tr td {
      padding: 0 4px;
    }

    tr.marquee2 {
      td:first-child {
        border-left: 2px solid;
        border-left-color: firebrick;
        background-color: yellow;
      }
    }

    tr.marquee {
      // https://stackoverflow.com/a/14499545/5949841
      td:first-child {
        position: relative;
      }

      td:first-child:after { /* Magic Happens Here!!! */
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        display: block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-top: 7px solid crimson;
      }
    }
  }
}
