@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.table-wrapper {
    max-width: 100%;
    overflow: scroll;
    max-height: calc(100vh - 84px);
    // If Report Table has less number of columns width should be adjusted to it's content instead of max-size
    width: min-content;
}

table.custom-report-table {
    font-family: awsui.$font-family-base;
    background-color: awsui.$color-background-container-content;
    position: relative;
    border-collapse: separate;
    border: 1px solid darkgrey;
    font-size: 14px;
    width: 100%;
    padding: 10px;

    // styling for empty row throughout table
    tr.empty-row {
        height: 10px;
    }

    // styling for empty cell throughout table
    td.empty-cell{
        background-color: awsui.$color-background-container-content;
    }

    // styling for empty cell throughout table
    th.empty-cell {
        background-color: awsui.$color-background-container-content;
        position: sticky;
        //To make z-index more than Button of polaris
        z-index: 5000;
        left: 0;
    }

    // styling for empty column break cells throughout table
    th.column-break-cell, td.column-break-cell {
        background-color: awsui.$color-background-container-content;
        text-align: center;
        width: 10px;
    }

    // styling specific to table header rows/cells
    thead {
        text-align: center;
        font-style: italic;
        padding: 12px 15px;
        //font-size: 0.75em;

        // Table head rows
        tr.span-title-row {
            background: white;
            white-space: nowrap;
            font-style: normal;
            position: sticky;
            top: 0;
            z-index: 100;
            height: 25px;
            th {
                font-weight: 500;
            }
        }
        tr.alphabets-row {
            background-color: #d3d3d3;
            padding: 12px 15px;
            position: sticky;
            //To make z-index more than POPOVER of polaris
            z-index: 5000;
            top:24px;
            height: 25px;
            th.alphabet-cell {
                max-width: 100px;
                &:active, &:hover {
                    background-color: gray;
                }
            }
        }
        tr.column-title-row {
            background-color: black;
            white-space: normal;
            color: awsui.$color-background-container-content;
            position: sticky;
            top: 48px;
            z-index: 100;
            font-size: 12px;
            th.column-title-cell {
                padding: 0.5px 2px;
                width:120px;
                max-width:120px;
            }
        }
        // Table head cells
        th.text-column-title {
            background-color: awsui.$color-background-container-content;
            color: black;
            text-align: center;
            width:350px;
            max-width: 350px;
            white-space: nowrap;
            position: sticky;
            left: 0px;
        }
    }
    // styling specific to table body rows/cells
    tbody {
        white-space: nowrap;
        // styling specific to table body rows
        tr.underlined_row {
            font-weight: bold;
            text-decoration-line: underline;
            text-decoration-thickness: 2px;
            padding: 5px 2px;
        }
        tr.impor_row, tr.sub-summary-line {
            font-weight: bold;
        }
        /*
         i) Styling only the column name(Eg: Contribution Margin) of ReportTable which have tr_style in stylesheet excel
         as italic.
         ii) Requirement is all metrics which are percentage their column name in ReportTable must be italic.
         iii) ReportTable has column name as second column in a row, Except column name other cells are text value
        */
        tr.italic td:nth-child(2) {
            font-style: italic;
        }
        // styling specific to table body cells
        td {
            padding: 0.5px 4px;
        }
        td.text-cell {
            text-align: left;
        }
        td.first_column {
            background-color: black;
            color: awsui.$color-background-container-content;
            text-align: center;
            padding: 1px 4px;
            font-weight: normal;
            width: 20px;
        }
        td.secondLevel {
            padding-left: 15px;
        }
        td.thirdLevel {
            padding-left: 25px;
        }
    }
}

.custom-report-table tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
}

.custom-report-table tbody tr td:nth-child(2) {
    background-color: awsui.$color-background-container-content;
    position: sticky;
    left: 24px;
}
